import React, {useEffect} from 'react';
import SocialMedia from './socialmedia';
import logo from '../assets/img/logo.png';
import classes from './success.module.css';


// import SocialMediaButtons from 'react-social-media-buttons';

function Success() {
	useEffect(()=>{
        document.title = "Contact";
    })

		return(
			<div>
				{/* <h2 style={{color: "black", margin: "30px"}}></h2> */}
				<div className={classes.centre_pos}>
					<a href="https://www.inclusivebusinesslab.org/">
						<img style={{height: "100px"}} src={logo} alt="Logo" />
					</a>
				</div>
				<h2 >
					
					Welcome to our community
					<br/>
					We will be in touch shortly
				</h2>
				<div className={classes.social}>
					<SocialMedia />
				</div>
			</div>
			
		  
		);
	
}

export default  Success;