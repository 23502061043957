import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import { createTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

// const theme = createTheme({
//     typography: {
//       fontFamily: [
//         'Montserrat',
//       ].join(','),
//     },
//   });

export default function MouseOverPopover(props) {
    
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  return (
    <div style={{display: "inline-flex"}} >
        <Typography 
            component={'div'}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >   
            {props.hover}
            {/* Hover with a Popover. */}
        </Typography>
      
        <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
            paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        >
        <Typography component={'div'} style={{font: "12px/20px Montserrat", padding: "5px"}}>
            {props.text}
        </Typography>
        </Popover>
    </div>
  );
}