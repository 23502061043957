import logo from './assets/img/logo.png';
import './App.css';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import PopUp from './component/popUp';
import MtCaptcha from './component/MTCaptcha';
import * as popupText from './component/texts/popuptexts';
import * as popupHover from './component/texts/popuphover';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);
API.configure(awsconfig);
function App() {
  var navigate = useNavigate();
  const enameRef = useRef();
  const cnameRef = useRef();
  const emailRef = useRef();
  const cemailRef = useRef();
  const orgRef = useRef();
  const phoneRef = useRef();
  //const subjectRef = useRef();
  const projectRef = useRef();
  const messageRef = useRef();
  const privacyRef = useRef();
  const otherRef = useRef();
  const [file, fileHandlerSet] = useState({ fileUpload: '', isFileExist: false });
  const [subjectstate, subjectSet] = useState({ subject: '', show: false });
  const [button, buttonset] = useState({active : true});
  const fileHandler = (e) => {
    let files = e.target.files[0];
    if (files.size > 10 * 1024 * 1024) {
      swal("The file exceeds 10MB", "", "error");
      document.getElementById("file").value = null;
      fileHandlerSet({ fileUpload: '', isFileExist: false });
    }
    else {

      fileHandlerSet({ fileUpload: files, isFileExist: true });
    }
  }
  const subjectHandler = (event) => {
    let subject = event.target.value;
    if (subject === 'others') {

      subjectSet({ show: true });
    } else {
      subjectSet({ subject: subject, show: false });
    }
    console.log(subjectstate.subject);
  }
  async function submitHandler(event) {
    event.preventDefault();
    if (cemailRef.current.value !== emailRef.current.value) {
      swal("Please enter the same email.", "", "error");
      return;
    } else {
      const data = new FormData(event.target);
      if (data.get('mtcaptcha-verifiedtoken') === '') {
        swal("Please check the MTCaptcha box!", "", "error");
        return;
      }
    }
    buttonset({active:false});
    const formData = {
      body: {
        "ENAME": enameRef.current.value,
        "CNAME": cnameRef.current.value,
        "EMAIL": emailRef.current.value,
        "DATE": new Date().toString(),
        "ORG": orgRef.current.value,
        "PHONE": phoneRef.current.value,
        "SUBJECT": subjectstate.show ? otherRef.current.value : subjectstate.subject,
        "PROJECT": projectRef.current.value,
        "MESSAGE": messageRef.current.value,
        "RECEIVE": privacyRef.current.checked,
        "ATTACHMENT": file.isFileExist ? file.fileUpload.name : ""
      }
    }
    //console.log(formData);
    var result = await API.put('involvedapi', '/items', formData);
    //console.log(file.fileUpload.name);
    if (file.isFileExist) {
      var result2 = await API.post('involvedapi', '/contactGetUrl-dev', { body: { name: file.fileUpload.name, content: file.fileUpload.type } });
      //console.log(file.fileUpload);
      var result3 = await fetch(result2.uploadURL, {
        method: "PUT",
        body: file.fileUpload,
      })
     // console.log(result2);
     // console.log(result3);
    }

    if (result.status === 200) {
      if(file.isFileExist){
        if(result3.status !== 200){
          swal("Error", "The is a problem while uploading your file, please try to submit adter a few seconds", "error");
          buttonset({active:true});
          return false;
        }
      }
      navigate("/success");
      console.log(result.success);
    } else {
      swal("Error", "The server cannot process your response, please try to submit after a few seconds!", "error");
      buttonset({active:true});
      return false;
    }
  }
  return (
    <div className="App">
      <title>Contact</title>
      <div className="content">
        <div className="logo-pos">
          <img style={{ height: "80px" }} src={logo} alt="Logo" />
        </div>
        <h1>Contact Us</h1>
        <div className="right">(*required)</div>
        {/* <!-- input form --> */}
        <form method="POST" onSubmit={submitHandler}>
          <div className="row">
            <div className="column">
              <label>*Full name (English/Pinyin):</label>
              <input id="ename" type="text" name="ename" placeholder="English/Pinyin, if from Mainland China"
                ref={enameRef} required />
            </div>
            <div className="column">
              <label>Full name (Chinese, if any):</label>
              <input id="cname" type="text" name="cname" placeholder="中文名"
                ref={cnameRef} />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label>*Email:</label>
              <input id="email" type="email" name="email" placeholder="Please use your email ID if you are already enrolled" required="required"
                ref={emailRef} />
            </div>
            <div className="column">
              <label>*Confirm Email:</label>
              <input id="cemail" type="email" name="cemail" required="required"
                ref={cemailRef} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Organisation/School (optional):</label>
              <input id="org" type="text" name="org" placeholder="Name/URL of the organisation"
                ref={orgRef} />
            </div>
            <div className="column">
              <label>Phone number (optional):</label>
              <input id="phone" type="text" name="phone" placeholder="Please specify your country code e.g. (852) XXXX XXXX"
                ref={phoneRef} />
            </div>
          </div>

          <div className="row">
            {/* <!-- single choice dropdown list --> */}
            <div className="input-group">
              <label>*Subject/Interested in:</label>
              <select name="subject" id="subject" defaultValue="DEFAULT" required
                onChange={subjectHandler}>
                {/* <!-- + multiple + ctrl while selecting --> */}
                <option value="DEFAULT" disabled>---your choice---</option>
                <option value="stem">STEM+</option>
                <option value="leadership">leadership Roundtable</option>
                <option value="photography">photography</option>
                <option value="filming">filming</option>
                <option value="podcast">podcast</option>
                <option value="music">music</option>
                <option value="art">art &amp; Design</option>
                <option value="story">storytelling</option>
                <option value="data">data Science</option>
                <option value="computing">computing</option>
                <option value="others">other (please specify)</option>
              </select>
              {subjectstate.show === true ?
                <div className="row">
                  <input id="other" type="text" name="other" placeholder="Others..." required="required"
                    ref={otherRef} />
                </div> :
                <div />
              }
              {/* <!-- <input id="subject" type="text" name="subject" placeholder="your choice" required="required"> --> */}
            </div>
          </div>
          <div className="row">
            <div className="input-group">
              <label>Project/Job Reference (if any):</label>
              <input id="project" type="text" name="project" placeholder="e.g. Inclusive Music 2021, JD:STEM+ Fellow"
                ref={projectRef} />
            </div>
          </div>
          <div className="row">
            <div className="input-group">
              <label>*Your message/story:</label>
              <textarea id="message" name="message" rows="5" placeholder="Type your message here.&#10;
                      If you are submitting a story, please type your story in both English and in your own language."
                ref={messageRef} required>
                {/* oninvalid="this.setCustomValidity('Please type your message or story here.')"
                        oninput="setCustomValidity('')"> */}
              </textarea>
            </div>
          </div>
          <div className="row">
            <div className="input-group">
              <label htmlFor="attachment">Attachment:</label>
              <PopUp hover = {<popupHover.filereq />} text = {<popupText.filereq />}/>
              <input type="file" id="file" name="file" accept=".png, .jpg, .pdf, .mp3, .wav, .mp4, .mov" onChange={fileHandler} />
            </div>
          </div>
          <input type="hidden" id="upload" name="upload" />
          <input type="hidden" id="container" name="container" />

          {/* <!-- checkbox to agree with the terms --> */}
          <div className="row">
            <div className="checkboxes">
              <label>
                <input type="checkbox" id="privacy" name="privacy" ref={privacyRef} />
                <span>&nbsp;I agree to receive your electronic communications and accept the data privacy statement.</span>
                <br />
                <span className="caption">You may unsubscribe at any time using the link in our newsletter.</span>
              </label>

            </div>
          </div>
          <PopUp text ={<popupText.privacy />} hover = {<popupHover.privacy/>}/>
          <div className="row">
            <p>Please fill in the captcha below to continue.</p>
          </div>
          {/* mtcaptcha checkbox */}
          <MtCaptcha />
          <div id="check-captcha"></div>
          <div className="row centre">
            <button type="submit" id="submit" disabled = {button.active ? false : true}className="submit-button">SUBMIT</button>
          </div>

        </form>
       
      </div>
    </div>
  );
}

export default App;
