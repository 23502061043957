export function filereq(){
    return (
    <div>It could be in form of .png/ .jpg/ .pdf/ .mp3/ .wav/ .mp4/ .mov
    <br/>File size limit: 10MB
    </div>
    );
}
export function privacy(){
    return (
        <div style={{width:'500px'}}>    No information we receive from you will be disclosed by us to any 
            party in a form that would identify you, except for the purposes 
            permitted under the Personal Data (Privacy) Ordinance, such as the 
            prevention or detection of crime. You have the right to request access 
            to, and correction of, information about you held by us. If you wish 
           to exercise this right, please contact us.
        </div>
        );
}
export function wechat(){
    return(
        <div>WeChat: InclusiveBusinessLab</div>
    );
}