export function filereq(){
    return (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="0.9em" height="0.9em" fill="currentColor" aria-hidden="true">
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
    </svg>
    );
}
export function privacy(){
    return (
        <span><u>Data privacy statement</u>
        </span>
        );
}
export function wechat(){
    return(
        <svg width="40" height="40" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#45B035"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.9642 25.728C40.752 25.7163 40.5386 25.7141 40.3232 25.7141C32.6602 25.7141 26.4437 30.9216 26.4437 37.3483C26.4437 38.4 26.6154 39.4187 26.928 40.3883C26.5888 40.4053 26.2474 40.4192 25.904 40.4192C24.2197 40.4192 22.5962 40.1909 21.0826 39.7696C20.9962 39.744 20.8234 39.695 20.8234 39.695L15.3152 42.5003L16.7872 37.8891C16.7872 37.8891 16.6944 37.8304 16.6474 37.8006C13.0112 35.472 10.6666 31.8038 10.6666 27.6768C10.6666 20.6389 17.488 14.9333 25.904 14.9333C33.5264 14.9333 39.8421 19.6128 40.9642 25.728ZM20.6016 25.36C19.4197 25.36 18.4618 24.4309 18.4618 23.2875C18.4618 22.1429 19.4197 21.2171 20.6016 21.2171C21.7834 21.2171 22.7434 22.1429 22.7434 23.2875C22.7434 24.4309 21.7834 25.36 20.6016 25.36ZM31.2064 21.2171C32.3882 21.2171 33.345 22.1429 33.345 23.2875C33.345 24.4309 32.3882 25.36 31.2064 25.36C30.0234 25.36 29.0677 24.4309 29.0677 23.2875C29.0677 22.1429 30.0234 21.2171 31.2064 21.2171Z" fill="white"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M40.4396 48.19C33.3292 48.19 27.5638 43.3697 27.5638 37.4231C27.5638 31.4785 33.3292 26.6583 40.4396 26.6583C47.5468 26.6583 53.31 31.4785 53.31 37.4231C53.31 40.9111 51.3281 44.0097 48.2572 45.9767L48.1388 46.0503L49.3825 49.9479L44.7308 47.5756C44.7308 47.5756 44.5846 47.6193 44.511 47.6385C43.2332 47.9959 41.8614 48.19 40.4396 48.19ZM34.1505 33.7143C34.1505 32.75 34.9612 31.966 35.9585 31.966C36.958 31.966 37.7676 32.75 37.7676 33.7143C37.7676 34.6828 36.958 35.4679 35.9585 35.4679C34.9612 35.4679 34.1505 34.6828 34.1505 33.7143ZM43.1094 33.7143C43.1094 32.75 43.918 31.966 44.9164 31.966C45.9158 31.966 46.7244 32.75 46.7244 33.7143C46.7244 34.6828 45.9158 35.4679 44.9164 35.4679C43.918 35.4679 43.1094 34.6828 43.1094 33.7143Z" fill="white"/>
         </svg>
    );
}